import React from "react"
import styles from "./index.module.scss"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import Footer from "../components/footer"

export default ({ location }) => {
	const intl = useIntl()
	return (
		<div className={styles.ctr}>
			<SEO
				title={intl.formatMessage({ id: "site.short_name" })}
				pathname={location.pathname}
				siteName="site.short_name" />
			<header className={styles.header}><FormattedMessage id="site.name" /></header>
			<Footer />
			<Link to="/buckmemo" className={styles.entry}>
				<img className={styles.icon} src="/img/fb-200x200.png" alt="App Icon" />
		    <div className={styles.name}><FormattedMessage id="buckmemo.name" /></div>
		    <div className={styles.desc}><FormattedMessage id="buckmemo.subname" /></div>
			</Link>
		</div>
	)
}